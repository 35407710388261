import React from "react";
import "../styles/ProductInfo.css";
import { useInfo } from "../context/PageContext";

function ProductInfo({ data }) {
  const prodRowB = [
    "Vi vet att våra portar håller - och att de håller vad vi lovar. Därför ingår alltid 10 års garanti oavsett vilken lösning du väljer.",
  ];

  return (
    <div className="product-Info-Container">
      {data?.Row ? (
        <div className="product-Info-Row">
          <div className="product-Row-Box">
            <header className="product-Rbox-Titel">
              {data?.Row[0].Header.toUpperCase()}
            </header>
            <p className="product-Rbox-Body">{data?.Row[0].Body}</p>
          </div>
          <div className="product-Row-Box">
            <header className="product-Rbox-Titel">
              {data?.Row[1].Header.toUpperCase()}
            </header>
            <p className="product-Rbox-Body">{data?.Row[1].Body}</p>
          </div>
          <div className="product-Row-Box">
            <header className="product-Rbox-Titel">
              ETT LÖFTE MED 10 ÅRS GARANTI
            </header>
            <p className="product-Rbox-Body">{prodRowB[0]}</p>
          </div>
        </div>
      ) : (
        []
      )}
      {data?.MidBox ? (
        <div className="product-Info-Body">
          <div className="product-Body-Box">
            <div className="product-Bbox-Info">
              <div className="product-Bbox-Conainer">
                <header className="product-Bbox-Title">
                  {data?.MidBox.Header.toUpperCase()}
                </header>
                <p className="product-Bbox-Body">{data?.MidBox.Body}</p>
              </div>
              <img src={data?.MidBox.Img} className="product-Bbox-Img"></img>
            </div>
          </div>
        </div>
      ) : (
        []
      )}
      {data?.InfoCards ? (
        <div className="product-Bbox-Info">
          {data?.InfoCards.map((card) => (
            <div className="product-Bbox-Card">
              {card.Img ? (
                <img src={card.Img} className="product-Card-Img"></img>
              ) : (
                []
              )}
              <header className="product-Bbox-Title">
                {card.Header.toUpperCase()}
              </header>
              <p className="product-Bbox-Body">{card.Body}</p>
            </div>
          ))}
        </div>
      ) : (
        []
      )}

      {data?.SpecField ? (
        <div className="product-Info-Body">
          <div className="product-Body-Box">
            <div className="product-Bbox-Info">
              {data?.SpecField[0].Img ? (
                <img
                  src={data?.SpecField[0].Img}
                  className="product-Bbox-Img"
                ></img>
              ) : (
                []
              )}
              <div className="product-Bbox-Conainer">
                <div className="product-Bbox-List">
                  <header className="product-Bbox-Title">
                    {data?.SpecField[0].Header.toUpperCase()}
                  </header>
                  {data?.SpecField[0].Items.map((item) => (
                    <p className="product-Bbox-Item">{item}</p>
                  ))}
                </div>
              </div>
            </div>
            {data?.SpecField[1] ? (
              <div className="product-Bbox-Info">
                <div className="product-Bbox-Conainer">
                  <div className="product-Bbox-List">
                    {data?.SpecField.slice(1, data?.SpecField.length).map(
                      (card) => (
                        <>
                          <header className="product-Bbox-Title">
                            {card.Header?.toUpperCase()}
                          </header>
                          {card.Items.map((item) => (
                            <p className="product-Bbox-Item">{item}</p>
                          ))}
                        </>
                      )
                    )}
                  </div>
                </div>
                {data?.SpecField[1]?.Img ? (
                  <img
                    src={data?.SpecField[1]?.Img}
                    className="product-Bbox-Img"
                  ></img>
                ) : (
                  []
                )}
              </div>
            ) : (
              []
            )}
          </div>
        </div>
      ) : (
        []
      )}
    </div>
  );
}

export default ProductInfo;
