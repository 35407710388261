import React, { useEffect } from "react";
import NavBar from "../components/WhiteNavBar";
import FooterBar from "../components/FooterBar";
import ProductGrid from "../components/ProductGrid";
import { TabTitle } from "../context/GeneralFunctions";
import Hamlet from "react-helmet";

function ProductPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Hamlet>
        <title>Industriportar - BBK International AB</title>
        <meta
          name="description"
          content="Vårat utbud av EABs högkvalitativa industriportar, Vi monterar och säljer allt från vikportar, skjutportar och slagportar till branddörrar och termofönster."
        />
      </Hamlet>
      <NavBar></NavBar>
      <div className="Body">
        <ProductGrid></ProductGrid>
      </div>
      <FooterBar></FooterBar>
    </>
  );
}

export default ProductPage;
