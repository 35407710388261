import React from "react";
import "../styles/FooterBar.css";
import { Link } from "react-router-dom";

function FooterBar() {
  return (
    <div className="footerBar-Container">
      <div className="footerBar-info-Container">
        <nav className="footerBar-Kontakt-Container">
          <h3 className="footerBar-Kontakt-Header">PORTAR</h3>
          <div className="footerBar-Kontakt-List">
            <Link className="footerBar-Link" to={"/portar/Vikportar"}>
              <p className="footerBar-Kontakt-Info">Vikportar</p>
            </Link>
            <Link className="footerBar-Link" to={"/portar/Skjutportar"}>
              <p className="footerBar-Kontakt-Info">Skjutportar</p>
            </Link>
            <Link className="footerBar-Link" to={"/portar/Slagportar"}>
              <p className="footerBar-Kontakt-Info">Slagportar</p>
            </Link>
            <Link className="footerBar-Link" to={"/portar/Säkerhetsdörrar"}>
              <p className="footerBar-Kontakt-Info">Säkerhetsdörrar</p>
            </Link>
            <Link className="footerBar-Link" to={"/portar/Branddörrar"}>
              <p className="footerBar-Kontakt-Info">Branddörrar</p>
            </Link>
            <Link className="footerBar-Link" to={"/portar/Termoportar"}>
              <p className="footerBar-Kontakt-Info">Termoportar</p>
            </Link>
            <Link className="footerBar-Link" to={"/portar/Maskinskyddsportar"}>
              <p className="footerBar-Kontakt-Info">Maskinskyddsportar</p>
            </Link>
            <Link className="footerBar-Link" to={"/portar/Termofönster"}>
              <p className="footerBar-Kontakt-Info">Termofönster</p>
            </Link>
          </div>
        </nav>
        <div className="footerBar-Kontakt-Container">
          <h3 className="footerBar-Kontakt-Header">KONTAKT</h3>
          <div className="footerBar-Kontakt-List">
            <p className="footerBar-Kontakt-Info">Tel: 070-770 87 47</p>
            <p className="footerBar-Kontakt-Info">Mail: christer@bebeco.com</p>
            <p className="footerBar-Kontakt-Info">
              Adress: Murgrönsvägen 4, 146 48 Tullinge
            </p>
          </div>
        </div>
        <nav className="footerBar-Kontakt-Container">
          <h3 className="footerBar-Kontakt-Header">SIDOR</h3>
          <div className="footerBar-Kontakt-List">
            <Link className="footerBar-Link" to={"/"}>
              <p className="footerBar-Kontakt-Info">HEM</p>
            </Link>
            <Link className="footerBar-Link" to={"/portar"}>
              <p className="footerBar-Kontakt-Info">PORTAR</p>
            </Link>
            <Link className="footerBar-Link" to={"/about"}>
              <p className="footerBar-Kontakt-Info">OM OSS</p>
            </Link>
            <Link className="footerBar-Link" to={"/contact"}>
              <p className="footerBar-Kontakt-Info">KONTAKT</p>
            </Link>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default FooterBar;
