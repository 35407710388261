import React, { useEffect } from "react";
import "../styles/Contact.css";
import FooterBar from "../components/FooterBar";
import NavBar from "../components/WhiteNavBar";
import { TabTitle } from "../context/GeneralFunctions";
import ContactMailForm from "../components/ContactMailForm";

function Contact() {
  TabTitle("Kontakt - BBK International AB");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <NavBar></NavBar>
      <div className="ContactPage-Container">
        <div className="contact-Info-Container">
          <div className="contact-Info-Box">
            <h1 className="contact-Header">KONTAKTA OSS</h1>
            <p className="contact-Body">Mail: christer@bebeco.com</p>
            <p className="contact-Body">Tel: 070-770 87 47</p>
            <p className="contact-Body">
              Adress: Murgönsvägen 4, 14648 Tullinge
            </p>
          </div>
        </div>
        <ContactMailForm></ContactMailForm>
      </div>
      <FooterBar></FooterBar>
    </>
  );
}

export default Contact;
