import React from 'react'
import "../styles/BigBanner.css"
import BannerButton from './BannerButton'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useInfo } from '../context/PageContext'
gsap.registerPlugin(ScrollTrigger)


function BigBanner(props) {
    const [info] = useInfo()
    const id = useParams()
    const bannerHeader = ["VI KAN PORTAR!", "ÄR DU I BEHOV AV NY INDUSTRIPORT?","VEM ÄR DU?","HÖR AV DIG TILL OSS!"]
    const bannerBody = ["BBK International AB har sålt och monterat industriportar sedan 1987. Vi vet vad som fungerar och passar i olika miljöer och kan därmed erbjuda både hållbara produkter och kringtjänster. Industriportarna I vårat sortiment kan både annpassas direkt efter önskemål eller köpas i ett standardiserat utförande.",
    "Oavsett om du letar efter en port med hög designnivå, specifikt anpassad till dina behov eller vikportar och garageportar i ett mer standardiserat utförande har vi den port du letar efter. Vi erbjuder även säkerhetsdörrar, termoportar, branddörrar, slagportar, skjutportar, m.m. Mixen av vårat sortiment samt den höga kvalitén på portarna gör oss till en stark samarbetspartner vid byggnation.",
    "Våra kunder finns inom allt från lager och industri till kund-anpassade P-garage och flerfamiljsfastigheter. Gemensamt för våra kunder är att de ställer tydliga krav på funktion, utseende och hållbarhet.","Hör av dig till oss för mer information om pris, utförande, offert eller för ställa frågor. Du kan även använda vårat formulär för skicka en prisföfrågan, ifall du undrar över vad en port kostar. Vi garanterar att vi kommer göra allt för att du ska bli nöjd."]
    const even = (props.sort + 1) % 2
    const bannerRef = useRef(null)

  useEffect(()=>{
    let xAmount
    if(even === 0){
      xAmount = 500
    }else{
      xAmount = -500
    }
    gsap.fromTo(`#bBC-${props.sort}`, {x:xAmount, opacity: 0,}, {x: 0, opacity:1, duration: .2, scrollTrigger: {trigger:`#bBC-${props.sort}`, start: "center bottom"}})
  },[])

  

    setTimeout(() => {
          if(even === 0){
            const body = document.getElementById(`bBCR${props.sort}`)

          }
    }, 5);

  return (
    <>  
         <div ref={bannerRef} id={`bBC-${props.sort}`} className='bigBanner-Container'>{
            even === 0 ? ( 
            <>
                <div id={`bBCR${props.sort}`} className='bigBanner-Body-Container'>
                  <div id={`content${props.sort}`} className='bigBanner-Content'>
                    <h2 className='bigBanner-Header'>{bannerHeader[props.sort]}</h2>
                    <p className='bigBanner-Body'>{bannerBody[props.sort]}</p>
                    <BannerButton btnTo="/contact" body="KONTAKTA OSS!"></BannerButton>
                  </div>
                    <div className='BigBanner-Background-Container'>
                    <img alt="En bild på en industriport direkt från vårt sortiment." className="BigBanner-background"src={info?.misc[0]?.BannerImgs[1]}></img>
                </div>
                </div>
            </>
            ):
            <>

                <div ref={bannerRef} id={`bBCR${props.sort}`} className='bigBanner-Body-Container'>
                      <div id={`content${props.sort}`} className='bigBanner-Content'>
                        <h2 className='bigBanner-Header'>{bannerHeader[props.sort]}</h2>
                        <p className='bigBanner-Body'>{bannerBody[props.sort]}</p>
                        <BannerButton btnTo="/about" body="LÄS MER!"></BannerButton>
                      </div>
                        <div className='BigBanner-Background-Container'>
                        <img alt="En bild på en industriport direkt från vårt sortiment." className="BigBanner-background"src={info?.misc[0]?.BannerImgs[0]}></img>
                </div>
                </div>
            </>
         }
        </div>
    
    </>
  )
}

export default BigBanner