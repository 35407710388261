import React, { useEffect, useState } from "react";
import ProductHero from "../components/ProductHero";
import ProductNav from "../components/ProductNav";
import NavBar from "../components/NavBar";
import WhiteNavBar from "../components/WhiteNavBar";
import ProductGallery from "../components/ProductGallery";
import ProductInfo from "../components/ProductInfo";
import FooterBar from "../components/FooterBar";
import "../styles/ArticlePage.css";
import { useInfo } from "../context/PageContext";
import { useParams } from "react-router-dom";
import ErrorPage from "./ErrorPage";
import "../styles/ArticlePage.css";
import { TabTitle, tabDescription } from "../context/GeneralFunctions";
import { getPageData } from "../calls/pageData";
import { Helmet } from "react-helmet";

function ArticlePage() {
  //const [info] = useInfo();
  const id = useParams();
  const [isLoading, setIsloading] = useState(true);
  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const [pageIndex, setPageIndex] = useState();

  TabTitle(`${id.typ} - BBK International AB `);

  function getCorrectSlug() {
    let slug = "";
    if (id.typ === "Vikportar") {
      slug = "Vikportar";
    } else if (id.typ === "Skjutportar") {
      slug = "Skjutportar";
    } else if (id.typ === "Slagportar") {
      slug = "Slagport";
    } else if (id.typ === "Säkerhetsdörrar") {
      slug = "Säkerhetsdörr";
    } else if (id.typ === "Branddörrar") {
      slug = "Branddörr";
    } else if (id.typ === "Termoportar") {
      slug = "Termoport";
    } else if (id.typ === "Maskinskyddsportar") {
      slug = "Maskinskyddsport";
    } else if (id.typ === "Termofönster") {
      slug = "Termofönster";
    }
    return slug;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      try {
        const res = await getPageData(getCorrectSlug());
        setData(res);
      } catch (error) {
        setError(error);
      } finally {
        setIsloading(false);
      }
    };

    getData();
  }, [id.typ]);

  const [scrollPos, setScrollPos] = useState(0);
  document.addEventListener("scroll", () => {
    setScrollPos(document.documentElement.scrollTop);
  });
  return (
    <>
      {isLoading ? (
        <div className="loading-Container">
          <p className="loading-txt">Laddar...</p>
        </div>
      ) : data && !error ? (
        <>
          <Helmet>
            <title>{id.typ} - BBK International AB</title>
            <meta name="description" content={data?.Hero.Body}></meta>
          </Helmet>
          {scrollPos < 400 ? (
            <NavBar></NavBar>
          ) : scrollPos > 400 ? (
            <WhiteNavBar></WhiteNavBar>
          ) : (
            []
          )}
          <ProductHero
            img={data?.Hero.Img}
            title={data?.Hero.Header.toUpperCase()}
            body={data?.Hero.Body}
          ></ProductHero>
          <ProductNav index={pageIndex}></ProductNav>
          <ProductGallery></ProductGallery>
          <ProductInfo data={data}></ProductInfo>
          <FooterBar></FooterBar>
        </>
      ) : (
        <ErrorPage></ErrorPage>
      )}
    </>
  );
}

export default ArticlePage;
