import React from "react";
import NavButton from "./NavButton";
import "../styles/NavBar.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faBars} from "@fortawesome/free-solid-svg-icons"
import SideBar from "./SideBar";
import { useSideBar } from "../context/SideBarContext";
import gsap from "gsap";
import { Link } from "react-router-dom";
import { useInfo } from "../context/PageContext";

function NavBar() {
  const [info] = useInfo()
  const [sideBar, setSideBar] = useSideBar()
  function openMenu(){
    const sMenu = document.getElementById("sideBar")
    sMenu.style.visibility = "visible"
    gsap.fromTo(".sideBar", {x:500}, {x:0, duration:0.2})
    setSideBar({type: "nav", open: true})
  }
  return (
    <div className="navBar-Container">
      <Link to={"/"}>
          <img
            className="navBar-Logo"
            src={info?.misc[0]?.Logo}
            alt="Company(BBK) blue and rend logo"
        ></img>
      </Link>
      <nav className="button-List-Container">
        <ul className="button-List">
          <NavButton color = "A" type="HEM"></NavButton>
          <NavButton color = "A" type="EAB PORTAR"></NavButton>
          <NavButton color = "A" type="OM OSS"></NavButton>
          <NavButton color = "A" type="KONTAKT"></NavButton>
        </ul>
      </nav>
      <div className="dd-Menu-Container">
        <FontAwesomeIcon onClick={(e) => openMenu()} icon={faBars} />
      </div>
      <SideBar></SideBar>
    </div>
  );
}

export default NavBar;
