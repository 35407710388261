import React,{useContext, useState, useEffect} from 'react'
import {db} from "./firebase"
import {getDocs, collection} from "firebase/firestore"

const PageContext = React.createContext();

export function useInfo(){
    return useContext(PageContext);
}


export function PageProvider({children}) {

    const [pageInfo, setpageInfo] = useState({data:[], misc:[], isLoading: false})

    async function getData(){
        setpageInfo({data:[], misc:[],isLoading: true})
        const tempInfo = []
        const tempMisc = []
        const thumbRef = collection(db, "Pages")
        let allThumbs = await getDocs(thumbRef)
        
        allThumbs.forEach((doc) => {
            tempInfo.push(doc.data())
        })

        const miscRef = collection(db, "Misc")
        let misc = await getDocs(miscRef)

        misc.forEach((doc) => {
            tempMisc.push(doc.data())
        })

      tempInfo.sort(function (a,b) {
        return a.DisplayNr - b.DisplayNr
      })
      setpageInfo({data:[...tempInfo],misc:[...tempMisc], isLoading: false})
    }

    useEffect(()=>{
        getData()
    },[])


  return (
    <PageContext.Provider value = {[pageInfo]}>
        {children}
    </PageContext.Provider>
  )
}

export default PageContext