import { db } from "../context/firebase";
import { getDoc, doc } from "firebase/firestore";

export async function getPageData(page) {
  console.log("Getting data");
  try {
    const docRef = doc(db, "Pages", page);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      // docSnap.data() will be undefined in this case
      throw new Error("404 - No such document!");
    }
  } catch (e) {
    throw new Error("Error getting document:", e);
  }
}
